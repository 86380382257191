.product-slider{
    &__prev,
    &__next{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        cursor: pointer;
    }
    &__next{
        right: 0;
    }
    .swiper-button-disabled{
        opacity: .5;
        pointer-events: none;
    }
}