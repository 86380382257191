@import "../../../../scss/core/functions/functions";
@import "../../../../scss/core/settings/settings";              // settings
@import "../../../../scss/core/mixins/mixins";
@import "../../../../scss/core/variables/variables";

.ui-email {
  &__fill {
    width: 100%;
    height: 40px;
    padding: 15px;
    border: 1px solid black;
    &_error {
      border-color: red;
    }
  }
}
