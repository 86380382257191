@import "../scss/core/functions/functions";
@import "../scss/core/settings/settings";              // settings
@import "../scss/core/mixins/mixins";
@import "../scss/core/variables/variables";

.app {
  margin-top: 60px;
}
.app-items {
  display: grid;
  grid-row-gap: 20px;
}
.app-button {
  background-color: black;
  color: #fff;
  padding: 10px 20px;
  margin-left: 20px;
}
.app-header {
  display: flex;
  align-items: stretch;
  margin-bottom: 20px;
}
