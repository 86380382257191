@import "../../scss/core/functions/functions";
@import "../../scss/core/settings/settings";              // settings
@import "../../scss/core/mixins/mixins";
@import "../../scss/core/variables/variables";

.modal-fade {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.modal-swipe {
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: #fff;
  width: 100%;
  padding: 16px;
  @include point-md {
    padding: 24px;
  }
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
  &__icon {
    display: block;
    width: 24px;
    height: 24px;
  }
}
.swipe-enter-active,
.swipe-leave-active {
  transition: transform 0.5s ease;
}

.swipe-enter-from,
.swipe-leave-to {
  transform: translateX(-100%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
