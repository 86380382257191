.product-main{
    &__title{

    }
    .price {
        font-weight: unset;
    }
    .cost{
        margin-top: 10px;
    }
    .show_class.bx_item_section_name{
        display: block;
        margin-bottom: 10px;
    }
    .table_sizes{
        margin-top: 10px;
    }
    .btn-lg{
        height: 40px;
    }
}