.slider-product-card{
    &__header{
        position: relative;
        &:not(:hover){
            .slider-product-card__hidden{
                opacity: 0;
                pointer-events: none;
            }
        }
        img{
            width: 100%;
            //height: 259px;
        }
    }
    &__hidden{
        opacity: 1;
        transition: .2s linear opacity;
    }
    &__view{
        position: absolute;
        width: 175px;
        left: calc((100% - 175px) / 2);
        bottom: 50px;
        text-align: center;
        height: 35px;
        text-transform: uppercase;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        opacity: 0.8;
        cursor: pointer;
        font-weight: 500;
    }
    &__main{
        text-align: center;
    }
    &__name{
        font-size: 12px;
        color: #2f2f2f;
        text-overflow: ellipsis;
    }
    &__status{
        color: #5fa800;
        font-size: 12px;
        &:before{
            content: '';
            display: inline-block;
            width: 5px;
            height: 5px;
            background-color: currentColor;
            border-radius: 50%;
            margin-right: 4px;
            transform: translateY(-2px);
        }
    }
    .title-inner{
        margin-top: 5px;
    }
}
.slider-product-labels{
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 12px;
    color: #fff;
    z-index: 2;
    &__item{
        padding: 3px 6px;
        &:not(:last-child){
            margin-right: 8px;
        }
    }
}
.slider-product-picture{
    position: relative;
    &__second{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}