@import "../../../../scss/core/functions/functions";
@import "../../../../scss/core/settings/settings";              // settings
@import "../../../../scss/core/mixins/mixins";
@import "../../../../scss/core/variables/variables";

.ui-checkbox {
  display: inline-flex;
  cursor: pointer;
  &__input {
    display: none;
    &:checked + .ui-checkbox__front {
      background-color: #000;
      border-color: #000;
      .ui-checkbox__icon {
        fill: #fff;
      }
    }
  }
  &__front {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #333;
    width: 18px;
    height: 18px;
    transition: 0.2s linear border-bottom-color, 0.2s linear background-color;
    &_error {
      border-color: red;
    }
  }
  &__icon {
    display: block;
    height: 18px;
    width: 18px;
    fill: transparent;
    transition: 0.2s linear fill;
  }
  &__text {
    margin-left: 10px;
  }
}
