@import "../../../../scss/core/functions/functions";
@import "../../../../scss/core/settings/settings";              // settings
@import "../../../../scss/core/mixins/mixins";
@import "../../../../scss/core/variables/variables";

.ui-button-primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #fff;
  height: 44px;
  padding: 0 15px;
  transition: 0.2s linear background-color;
  &:hover {
    background-color: rgb(70, 70, 70);
  }
  &:disabled {
    pointer-events: none;
  }
}
