.product-vertical-slider{
    position: relative;
    display: none;
    @include point-lg{
        display: block;
    }
    &__next,
    &__prev{
        position: absolute;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        z-index: 3;
        width: 24px;
        height: 24px;
    }
    &__prev{
        top: -24px;
    }
    &__next{
        bottom: -24px;
    }
    .swiper{
        height: 500px;
        img{
            width: 100%;
        }
    }
    .swiper-slide{
        & > div{
            height: 100%;
            overflow: hidden;
            border-bottom: 2px solid transparent;
            transition: .2s linear border-color;
            cursor: pointer;
            width: 100%;
        }
        &.swiper-slide-thumb-active{
            & > div{
                border-color: #404040;
            }
        }
    }
    .swiper-button-disabled{
        opacity: .5;
        pointer-events: none;
    }
}
.product-main-slider{
    img{
        width: 100%;
    }
    .swiper-scrollbar{
        position: absolute;
        bottom: 8px;
        left: 8px;
        right: 8px;
        height: 3px;
        z-index: 1;
        @include point-lg{
            display: none;
        }
    }
    iframe{
        //position: absolute;
        //left: 0;
        //right: 0;
        width: 100%;
        height: 100%;
        //top: 0;
        //bottom: 0;
        //@include point-md{
        //    height: 350px;
        //}
    }
    .swiper-wrapper{
        align-items: stretch;
    }
    .swiper-slide{
        width: 100%;
        height: unset;
    }
}

.product-detail-gallery__container {
    @include point-lg{
        display: grid;
        grid-template-columns: 100px minmax(0, 1fr);
        grid-column-gap: 16px;
    }
}
.slider-full-size{
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    z-index: 1;
    cursor: pointer;
}
.product-container .product-detail-gallery__picture {
    max-height: var(--slider-max-height);
}
.video-block.popup_video{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
}