@import "../../scss/core/functions/functions";
@import "../../scss/core/settings/settings";              // settings
@import "../../scss/core/mixins/mixins";
@import "../../scss/core/variables/variables";

.modal {
  background-color: #fff;
  position: relative;
  padding: 24px;
  &_wide {
    width: 100%;
  }
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
  &__icon {
    width: 16px;
    height: 16px;
  }
}
