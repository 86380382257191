.cabinet-menu{
    display: grid;
    grid-gap: 12px;
    &__exit{
        margin-top: 12px;
    }
    .active{
        color: var(--white_text_black2);
        font-weight: bold;
    }
}