@import "../scss/core/functions/functions";
@import "../scss/core/settings/settings";              // settings
@import "../scss/core/mixins/mixins";
@import "../scss/core/variables/variables";

.app-item {
  display: grid;
  grid-template-columns: 150px 1fr auto;
  align-items: center;
  padding: 20px;
  border: 1px solid black;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  &__button {
    padding: 10px 20px;
  }
}
