@import "../scss/core/functions/functions";
@import "../scss/core/settings/settings";              // settings
@import "../scss/core/mixins/mixins";
@import "../scss/core/variables/variables";

.app-input {
  flex-grow: 1;
  &__area {
    height: 100%;
    width: 100%;
    padding: 5px 15px;
  }
}
